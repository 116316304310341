import {
    deleteAlias,
    fetchAliases,
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector,
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';

export const useAliases = () => {

    const dispatch = useAppDispatch();


    const {
        items,
        visibleItems,
        visibleCount,
    } =
        useAppSelector((state) => visibilitySelector(state));


    const selection = useAppSelector((state) => selectionSelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );


    // FETCH
    const fetchRecords = () => {
        dispatch(fetchAliases());
    };

    const deleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deleteAlias(key));
            }
        }
    };


    return {
        items,
        visibleItems,
        visibleCount,
        loading,
        fetchRecords,
        deleteRecords,
    };
};
