import {
    deleteAlias,
    fetchAlias,
    saveAlias,
    updateAlias,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Alias} from '../types';

export const useAlias = (aliasId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, aliasId ? aliasId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof aliasId === 'undefined') {
            throw new Error('aliasId is required parameter');
        }
        dispatch(fetchAlias(aliasId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Alias) => {
        dispatch(saveAlias(values));
    };

    const updateRecord = (values: Alias) => {
        if (typeof aliasId === 'undefined') {
            throw new Error('aliasId is required parameter');
        }

        dispatch(updateAlias({
                                 id: aliasId,
                                 record: values,
                             }));
    };

    const deleteRecord = () => {
        if (typeof aliasId === 'undefined') {
            throw new Error('aliasId is required parameter');
        }
        dispatch(deleteAlias(aliasId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
