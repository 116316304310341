import {
    Button,
    Group,
    Text
} from "@mantine/core";
import {Tag} from "../../tags/types.ts";
import {useNavigate} from "react-router-dom";

export const TagsGroup = ({value}: { value: Tag[] | null | undefined }) => {
    const navigate = useNavigate()

    return (
        <Group gap={'xs'}>
            {value && value.map((tag, index) => (
                                    <Button key={index} variant="filled" radius={'lg'} size={'compact-xs'}
                                            onClick={() => navigate(`/tags/${tag.id}`)}><Text
                                        size={'0.8em'}>{tag.name}</Text></Button>
                                )
            )}
        </Group>
    );
};


