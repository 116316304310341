export const listPersonas = `
   query listPersonas($filter: PersonasFilterInput, $limit: Int, $nextToken: String) {
       listPersonas(filter: $filter, limit: $limit, nextToken: $nextToken) {
           items {
              id
              givenName
              familyName
              email
              note
              status
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getPersona = `
   query getPersona($id:ID!) {
       getPersona(id:$id) {
         id
         givenName
         familyName
         email
         note
         status
         createdByUsername
         created
         updated
       } 
}`;
