import {useAppDispatch} from '../../../hooks/useTypedReactRedux';
import {
    addTableColumn,
    setTableColumns
} from "../reducers";
import {Message} from "../types.ts";
import {
    Box,
    Group,
    Stack,
    Text
} from "@mantine/core";
import {AiOutlinePaperClip} from "react-icons/ai";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";


export const useMessagesTableColumns = () => {

    const dispatch = useAppDispatch();

    const columns = [];

    columns.push({
                     id: 'from',
                     accessor: 'fromText',
                     title: 'From',
                     ellipsis: true,
                     width: 500,
                     render: (item: Message) => {
                         return (
                             <Group>

                                 <Text fs={'sm'} fw={700} style={{cursor: 'pointer'}}>{item.fromText}</Text>
                                 <Box> {item.attachments && item.attachments.length > 0 ?
                                     <AiOutlinePaperClip/> : null}</Box>

                             </Group>
                         )

                     },

                 },
    );

    columns.push({
                     id: 'subject',
                     accessor: 'subject',
                     title: 'Subject',
                     ellipsis: true,
                     render: (item: Message) => {
                         return (
                             <Stack>
                                 {item.subject ? <Text fw={700} fs={'sm'}>{item.subject}</Text> :
                                     <Text fs={'sm'} fw={700}>n/a</Text>}
                             </Stack>
                         )

                     },

                 },
    );

    columns.push({
                     id: 'date',
                     accessor: 'date',
                     title: 'Received',
                     render: (item: Message) => formatTimestamp(item.date as string, 'MMM DD, YYYY'),
                 });


    // FETCH
    const handleAddTableColumn = (column: object) => {
        dispatch(addTableColumn(column));
    };

    const handleSetTableColumns = (columns: object[]) => {
        dispatch(setTableColumns(columns));
    };


    return {
        columns,
        handleAddTableColumn,
        handleSetTableColumns,
    };
};
