import {NavLink} from 'react-router-dom';

import {
    Box,
    Breadcrumbs,
} from '@mantine/core';

export const IndexPageNavigation = () => {

    const breadcrumbs = [{
            title: 'Home',
            href: '/dashboard',
        },
            {title: 'Contacts'}].map((item, index) => (
            <Box key={index}> {
                item
                    .href ?
                    <NavLink to={item.href}>{item.title}</NavLink> : item.title
            }
            </Box>
        ))
    ;
    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );
};

