import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/ContactsService.tsx';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Contact} from '../types';

const entityKey = 'contacts';

interface ContactsState {
    byId: { [key: string]: Contact },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as ContactsState;


const normalizeRecord = (record: Contact) => {
    return Object.assign({}, record) as Contact;
};

// generates pending, fulfilled and rejected
export const fetchContacts = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {


        return fetchRecords()
            .then((response) => {
                if (response) {
                    return response.data.items.map((record: Contact) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchContact = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response.data);
                }
            });
    });

export const saveContact = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Contact) => {

        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateContact = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: Contact }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(record as Contact);
                }
            });
    },
);

export const deleteContact = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchContacts, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchContact);

                                            // SAVE ONE
                                            saveOneCase(builder, saveContact);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateContact);


                                            // DELETE ONE
                                            deleteOneCase(builder, deleteContact);
                                        },
                                    });

export default componentsSlice.reducer;
