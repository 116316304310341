import {
    CognitoIdentityClient,
    GetIdCommand,
    GetIdCommandInput
} from '@aws-sdk/client-cognito-identity';
import {config} from "../../config/amplify";


const client = new CognitoIdentityClient({
                                             region: 'us-west-2',
                                         });

export const getIdentityId = async (idToken: string) => {

    const params = {
        IdentityPoolId: config.Auth?.Cognito.identityPoolId,
        Logins: {
            [`cognito-idp.us-west-2.amazonaws.com/${config.Auth?.Cognito.userPoolId}`]: idToken
        }
    } as GetIdCommandInput;

    const command = new GetIdCommand(params);
    return client.send(command);
}


