import {
    Avatar,
    Badge,
    Card,
    Center,
    Group,
    Stack,
    Text
} from '@mantine/core';
import classes from './MessageCard.module.css';
import {Message} from "../../types.ts";
import {MessageToolbar} from "../MessageToolbar.tsx";
import {formatTimestamp} from "../../../../utils/TableTimestamps.tsx";
import {TagsGroup} from "../TagsGroup.tsx";


export function MessageCard({
                                record,
                                handleUpdate,
                                handleDelete
                            }: {
    record: Message,
    handleUpdate: (record: Message) => void,
    handleDelete: () => void
}) {


    return (
        <Card withBorder padding="xl" radius="md" className={classes.card}>
            <Card.Section
                className={classes.banner}
                h={100}
            />
            <Avatar
                name={record.subject}
                size={80}
                radius={80}
                mx="auto"
                mt={-30}
                className={classes.avatar}
            />
            <Center mt={'sm'}>
                <Stack>
                    <Group gap={'sm'}>
                        <Text c="dimmed">{formatTimestamp(record.date as string)}</Text>
                    </Group>
                    <Group gap={'sm'}>
                        <Text ta="center" fz="lg" fw={500} c={'dimmed'}>
                            Status:</Text>
                        <Badge variant={'outline'}
                               color={record.status === 'READ' ? 'blue' : 'gray'}>{record.status}</Badge>
                    </Group>
                </Stack>
            </Center>
            <MessageToolbar record={record} handleUpdate={handleUpdate} handleDelete={handleDelete}/>
            {record.tags?.items && record.tags?.items.length > 0 ? <Card.Section className={classes.section}>
                <TagsGroup value={record.tags?.items}/>
            </Card.Section> : null}
        </Card>
    );
}