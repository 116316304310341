import {stage} from './stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        domain: string;
    };
};

const emailDomains: ConfigShape = {
    prod: {
        domain: 'flexi.email',
    },
    test: {
        domain: 'test.flexi.email',
    },
    dev: {
        domain: 'dev.flexi.email',
    },
    ide: {
        domain: 'dev.flexi.email',
    },
};

export default emailDomains[stage];
