import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {
    Box,
    Button,
} from '@mantine/core';

import {useNavigate} from 'react-router-dom';
import {AiOutlinePlus} from 'react-icons/ai'
import {useTagsSelection} from "../hooks/useTagsSelection.ts";
import {useTags} from "../hooks/useTags.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useTags();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add Tag',
                             size = 'sm',
                             onClick,
                         }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <Button
            variant={"filled"}
            disabled={false}
            leftSection={<AiOutlinePlus/>}
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </Button>
    );
};

const DeleteRecordButton = () => {

    const {deleteRecords} = useTags();

    const {
        selection,
        handleClearSelection,
    } = useTagsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }

    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (allowedActions.includes('DeleteTag')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes('CreateTag')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Tags')}
            tools={useTools()}
        />
    );
};
