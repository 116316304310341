import {I18n} from '@aws-amplify/core';


import {Button,} from '@mantine/core';

import {useNavigate} from 'react-router-dom';
import {AiOutlinePlus} from 'react-icons/ai'

export const AddRecordButton = ({
                                    buttonText = 'Add Alias',
                                    size = 'sm',
                                    onClick,
                                }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <Button
            variant={"filled"}
            disabled={false}
            leftSection={<AiOutlinePlus/>}
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </Button>
    );
};
