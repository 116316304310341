import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/PersonasService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Persona} from '../types';

import {recordSelector} from "../selectors";

const entityKey = 'personas';

interface PersonaState {
    byId: { [key: string]: Persona },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as PersonaState;


const normalizeRecord = (record: Persona) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchPersonas = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {
        const filter = {}
        return fetchRecords(filter)
            .then((response) => {
                if (response) {
                    return response.map((record: Persona) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchPersona = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const savePersona = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Persona) => {

        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updatePersona = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: {
        id: string,
        record: Persona
    }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deletePersona = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string, {getState}) => {

        const state = getState();
        const {record} = recordSelector(state, id);

        return deleteRecord(id, record.email)
            .then(() => id);
    },
);


export const storePersona = createAsyncThunk(
    `${entityKey}/store`,
    (payload: Persona) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchPersonas, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchPersona);

                                            // SAVE ONE
                                            saveOneCase(builder, savePersona);

                                            // UPDATE ONE
                                            updateOneCase(builder, updatePersona);

                                            // DELETE ONE
                                            deleteOneCase(builder, deletePersona);
                                        },
                                    });

export default componentsSlice.reducer;
