import {ServicePlan,} from '../types';
import {PlatformPropertyCard} from "@flexinet/ui-components";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const Profile = ({record}: { record: ServicePlan }) => {
    console.debug(record)

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 6,
        }, {
            label: 'Description',
            value: record.description as string,
            span: 6,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : null,
            rbac: rbacActions.UpdateServicePlan,
            span: 4,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 4,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 4,
        }];


    return (
        <PlatformPropertyCard properties={properties}/>
    );


};



