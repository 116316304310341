import {
    useLocation,
    useNavigate
} from 'react-router-dom';

import {Button} from '@mantine/core';
import {useToggle} from '@mantine/hooks';


import {
    AiOutlineArrowLeft,
    AiOutlineProfile
} from 'react-icons/ai';
import {IconContext} from 'react-icons';
import {
    FlexiButton,
    FlexiCloseButton,
    FlexiEditButton
} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const useEditPageTools = () => {

    const navigate = useNavigate();
    const location = useLocation()
    const canGoBack = location.key === 'default';

    const {allowedActions} = useVerifiedPermissions()

    const [mode, toggle] = useToggle(['view', 'edit']);

    const tools = [];

    tools.push(<FlexiButton key={'back'} leftSection={<AiOutlineArrowLeft/>} variant={'outline'}
                            onClick={() => navigate(-1)} disabled={canGoBack}>Back</FlexiButton>);

    if (allowedActions.includes('UpdateMessage')) {

        if (mode === 'edit') {
            tools.push(<Button radius='xs' variant={'filled'} key={'profile'}
                               leftSection={<IconContext.Provider value={{size: '24px'}}>
                                   <AiOutlineProfile/></IconContext.Provider>}
                               onClick={() => toggle()}>Profile</Button>);
        } else {
            tools.push(<FlexiEditButton key={'edit'} onClick={() => toggle()}/>);
        }

    }


    tools.push(<FlexiCloseButton key={'close'} onClick={() => navigate(`/messages`)}/>);

    return {
        mode,
        tools,
    };

};
