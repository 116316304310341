import {Message,} from '../types';
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";
import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator
} from "@flexinet/ui-components";
import {
    Accordion,
    Group,
    Paper,
    Stack,
    Text
} from "@mantine/core";
import {
    AiOutlinePaperClip,
    AiOutlineSetting
} from "react-icons/ai";
import {getUrl} from "aws-amplify/storage";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {MessageCard} from "./MessageCard";


export const Profile = (
    {
        record,
        handleUpdate,
        handleDelete
    }: {
        record: Message,
        handleUpdate: (record: Message) => void,
        handleDelete: () => void
    }) => {


    const properties = [
        {
            label: 'From',
            value: record.fromText,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }, {
            label: 'To',
            value: record.toText,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }, {
            label: 'Subject',
            value: record.subject,
            span: 12,
        }, {
            label: 'Received',
            value: formatTimestamp(record.date as string),
            span: 12,
        }];

    const metadata = [
        {
            label: 'Message ID',
            value: record.messageId as string,
            span: 12,
        }, {
            label: 'Status',
            value: record.status,
            span: 12,
        }, {
            label: 'Html Path',
            value: record.htmlPath,
            span: 12,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: rbacActions.UpdateMessage,
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: rbacActions.UpdateMessage,
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];


    const showAttachment = async (attachment: { filename: string, filePath: string, objectKey: string }) => {

        const getUrlResult = await getUrl({
                                              path: attachment.filePath
                                          });

        console.debug(getUrlResult.url)
        window.open(getUrlResult.url, '_blank')

    };

    return (
        <Stack gap={'xl'}>
            <MessageCard record={record} handleUpdate={handleUpdate} handleDelete={handleDelete}/>

            <PlatformPropertyCard properties={properties}/>

            <Accordion defaultValue={''}>
                <Accordion.Item key={'metadata'} value="metadata">
                    <Accordion.Control icon={<AiOutlineSetting/>}>Message Metadata</Accordion.Control>
                    <Accordion.Panel>
                        <PlatformPropertyCard properties={metadata}/>
                    </Accordion.Panel>
                </Accordion.Item>

            </Accordion>

            {record.attachments && record.attachments.length > 0 &&
                <Group p={'sm'}>
                    {record.attachments.map(attachment => <Paper withBorder
                                                                 p={'md'}
                                                                 shadow={'sm'}
                                                                 onClick={() => showAttachment(attachment)}
                                                                 style={{cursor: 'pointer'}}
                    ><Group><AiOutlinePaperClip
                        size={'20'}/><Text>{attachment.filename}</Text></Group></Paper>)}
                </Group>
            }

        </Stack>
    );

};
