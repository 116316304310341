import {AppLayout} from "../layout/AppLayout.tsx";
import {PublicHomePage} from "../features/home/pages/PublicHomePage.tsx";
import {SignOutPage} from "../features/home/pages/SignOutPage.tsx";
import {AboutPage} from "../features/home/pages/AboutPage.tsx";
import {ProductsPage} from "../features/home/pages/ProductsPage.tsx";
import {PricingPage} from "../features/home/pages/PricingPage.tsx";
import {RequireAuth} from "../utils/RequireAuth.tsx";
import {IndexPage as DashboardPage} from "../features/dashboard/pages/IndexPage.tsx";
import {MessagesLayout} from "../features/messages/layout.tsx";
import {AliasesLayout} from "../features/aliases/layout.tsx";
import {ContactsLayout} from "../features/contacts/layout.tsx";
import {ServicePlansLayout} from "../features/service-plans/layout.tsx";
import {TagsLayout} from "../features/tags/layout.tsx";
import {PersonasLayout} from "../features/personas/layout.tsx";

export const routesConfig = [{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'about',
        element: <AboutPage/>
    }, {
        path: 'products',
        element: <ProductsPage/>
    }, {
        path: 'pricing',
        element: <PricingPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'messages/*',
        element: <RequireAuth><MessagesLayout/></RequireAuth>
    }, {
        path: 'aliases/*',
        element: <RequireAuth><AliasesLayout/></RequireAuth>
    }, {
        path: 'tags/*',
        element: <RequireAuth><TagsLayout/></RequireAuth>
    }, {
        path: 'personas/*',
        element: <RequireAuth><PersonasLayout/></RequireAuth>
    }, {
        path: 'contacts/*',
        element: <RequireAuth><ContactsLayout/></RequireAuth>
    }, {
        path: 'service-plans/*',
        element: <RequireAuth><ServicePlansLayout/></RequireAuth>
    }]
}]