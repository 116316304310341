import {visibilityFilter} from '../../utils/VisibilityFilter';
import {RootState} from '../../store';
import {Message} from "./types.ts";

const key = 'messages';

export const recordsSelector = (state: RootState) => {
    return state[key].records;
};

export const recordSelector = (state: RootState, id: string) => {
    const records = recordsSelector(state);
    const loading = records.loading;
    const record = records.byId[id];
    return {
        record,
        loading,
    };
};

export const selectionSelector = (state: RootState) => {
    return state[key].selection;
};

export const visibilitySelector = (state: RootState, filter?: { tagId?: string, status?: string }) => {
    const items = messagesSelector(state, filter);


    const page = state[key].visibility.page;
    const limit = state[key].visibility.limit;
    const keyword = state[key].visibility.keyword;
    const visible = state[key].visibility.visible;

    const visibleItems = visibilityFilter(items, page, limit, keyword);

    const totalCount = state[key].records.totalCount;

    let visibleCount = totalCount;
    if (keyword) {
        visibleCount = visibleItems.length;
    }

    const totalPages = Math.ceil(visibleCount / limit);

    return {
        items: items,
        visibleItems: visibleItems,
        page: page,
        limit: limit,
        keyword: keyword,
        visible: visible,
        visibleCount: visibleCount,
        totalPages: totalPages,
    };
};

export const messagesSelector = (state: RootState, filter?: { tagId?: string, status?: string }) => {

    const all = state[key].records.ids.map((id: string) => {
        return state[key].records.byId[id];
    });
    if (filter?.tagId) {
        return all.filter((message: Message) => {
            const ids = message.tags?.items ? message.tags?.items.map(item => item.id) : [];
            return ids?.includes(filter.tagId as string);
        });
    }
    if (filter?.status) {
        return all.filter((message: Message) => {
            return message.status === filter.status;
        });
    }

    return all;
};

export const messageSelector = (state: RootState, id: string) => {
    return state[key].records.byId[id];
};

export const allComponentsSelector = (state: RootState) => {
    const all = state[key].records.ids.map((id: string) => {
        return state[key].records.byId[id];
    });
    return all;
};


