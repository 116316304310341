import {Field} from 'formik';

import {
    Box,
    Group,
    Radio,
    SimpleGrid,
    Stack
} from '@mantine/core';

import {
    FlexiButton,
    FormikRadioGroup,
    FormikTextarea,
    FormikTextInput
} from '@flexinet/ui-components';
import {Alias} from "../types.ts";
import {useState} from "react";
import {AiOutlineReload} from "react-icons/ai";


export const FormComponent = ({
                                  values,
                                  setFieldValue
                              }: { values: Alias, setFieldValue: (field: string, value: any) => void }) => {

    const editing = values.id !== undefined;

    const [generationMethod, setGenerationMethod] = useState('auto')

    return (
        <SimpleGrid cols={1}>

            <Radio.Group
                name='generationMethod'
                value={generationMethod}
                label='Alias Generation Method'
                description='Please select method'
                onChange={setGenerationMethod}

            >
                <Group mt="xs">
                    <Radio value="auto" label="Auto"/>
                    <Radio value="manual" label="Manual"/>
                </Group>
            </Radio.Group>

            {generationMethod === 'auto' && <Group>

                <Box><Field
                    name='name'
                    label='Name'
                    description='Generated Name'
                    component={FormikTextInput}
                /></Box>

                <Stack mt={'xl'}><Box> </Box><FlexiButton leftSection={<AiOutlineReload/>} onClick={() => {
                    const randomNumber = Math.random() + 1;
                    const name = randomNumber.toString(36)
                                             .substring(5);
                    console.debug('name', name);
                    setFieldValue('name', name);
                }}>Generate</FlexiButton>
                </Stack>
            </Group>}

            {generationMethod === 'manual' && <Field
                name='name'
                label='Name'
                description='Please enter the alias. E.g. "john.doe". The email address will be john.doe@flexi.email'
                component={FormikTextInput}
                withAsterisk
                disabled={editing}
            />}

            <Field
                name='description'
                label='Description'
                description='Please enter description'
                component={FormikTextarea}
            />

            <Field
                name='status'
                label='Status'
                description='Please enter status'
                data={[{
                    label: 'Enabled',
                    value: 'Enabled'
                }, {
                    label: 'Disabled',
                    value: 'Disabled'
                }]}
                component={FormikRadioGroup}
            />


        </SimpleGrid>
    );
};
