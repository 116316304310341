import {Navigate} from "react-router-dom";
import {MarketingComponent} from "../components/MarketingComponent.tsx";
import {useUserAttributes} from "../../../hooks/useUserAttributes.ts";


export const PublicHomePage = () => {

    const {email} = useUserAttributes();

    if (email) {
        return <Navigate to={'/dashboard'}/>;
    } else {
        return <MarketingComponent/>;
    }

};
