import {
    configureStore,
    Store
} from '@reduxjs/toolkit';
import {AliasesReducer} from '../features/aliases/reducers/index'
import {ContactsReducer} from '../features/contacts/reducers/index'
import {MessagesReducer} from "../features/messages/reducers";
import {ServicePlansReducer} from "../features/service-plans/reducers";
import {TagsReducer} from "../features/tags/reducers";
import {PersonasReducer} from "../features/personas/reducers";


const reducers = {
    messages: MessagesReducer,
    aliases: AliasesReducer,
    personas: PersonasReducer,
    contacts: ContactsReducer,
    servicePlans: ServicePlansReducer,
    tags: TagsReducer,
};

// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                           });


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch