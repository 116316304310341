import {
    createContext,
    FunctionComponent,
    ReactNode,
    useEffect,
    useState
} from 'react';
import {
    AuthUser,
    fetchAuthSession,
    getCurrentUser,
    JWT
} from 'aws-amplify/auth';


type AuthContextType = {
    user: AuthUser | undefined,
    idToken?: JWT | undefined,
    accessToken?: JWT | undefined,
    cognitoGroups?: string[] | undefined,
}


export const AuthContext = createContext<AuthContextType>({
                                                              user: {} as AuthUser,
                                                          });

interface IAuthProvider {
    children?: ReactNode
}

export const AuthProvider: FunctionComponent<IAuthProvider> = ({children}) => {

    const [user, setUser] = useState<AuthUser | undefined>();
    const [idToken, setIdToken] = useState<JWT | undefined>();
    const [accessToken, setAccessToken] = useState<JWT | undefined>();
    const [cognitoGroups, setCognitoGroups] = useState<string[] | undefined>();


    useEffect(() => {
        const fetchCognitoUser = async () => {

            try {
                const result = await getCurrentUser()
                console.log('AUTH CONTEXT. FETCH USER', result)

                const {
                    username,
                    userId,
                    signInDetails
                } = result

                setUser({
                            username,
                            userId,
                            signInDetails
                        })


            } catch (error) {
                console.trace(
                    'AUTH CONTEXT. FETCH USER ERROR',
                    error
                );
            }
        };

        fetchCognitoUser();

    }, []);


    useEffect(() => {
        const fetchSession = async () => {

            try {
                const {
                    accessToken,
                    idToken
                } = (await fetchAuthSession()).tokens ?? {};

                setIdToken(idToken);
                setAccessToken(accessToken);
                if (typeof idToken !== 'undefined'
                    && idToken.payload['cognito:groups'] !== undefined
                    && Array.isArray(idToken.payload['cognito:groups'])
                    && idToken.payload['cognito:groups'].length > 0) {
                    setCognitoGroups(idToken.payload['cognito:groups'] as string[]);
                }
            } catch (error) {
                console.trace(
                    'AUTH CONTEXT. FETCH SESSION ERROR',
                    error
                );
            }

        };

        fetchSession();
    }, []);


    return (
        <AuthContext.Provider value={{
            user,
            idToken,
            accessToken,
            cognitoGroups,
        }}>
            {children}
        </AuthContext.Provider>
    );
};
