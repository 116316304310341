import {
    Box,
    Center,
    Container,
    Grid,
    Paper,
    Stack,
    Text,
    Title
} from '@mantine/core';
import {CallToActionComponent} from "./CallToActionComponent.tsx";
import {ReactNode} from "react";
import {products} from "./product-descriptions.tsx";


export type ProductDescriptionType = {
    title: string,
    problem: string,
    solution: string,
    benefit: string,
    icon: ReactNode,
    position: string,
}

// product tile component
export const ProductTile = ({
                                product
                            }: {
    product: ProductDescriptionType
}) => {

    switch (product.position) {
        case 'left':
            return (
                <Paper shadow="xl" p="xl" withBorder>
                    <Grid>
                        <Grid.Col span={4} style={{
                            textAlign: 'center',
                            marginTop: '2em'
                        }}>
                            {product.icon}
                        </Grid.Col>

                        <Grid.Col span={8}>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Title order={3} style={{color: 'var(--mantine-color-dark-7)'}}>{product.title}</Title>
                            </Box>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Text fw={800}>Problem: </Text> <Text>{product.problem}</Text>
                            </Box>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Text fw={800}>Solution: </Text> <Text>{product.solution}</Text>
                            </Box>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Text fw={800}>Benefit: </Text> <Text>{product.benefit}</Text>
                            </Box>
                        </Grid.Col>

                    </Grid>
                </Paper>
            )
        case 'right':
            return (
                <Paper shadow="xl" p="xl" withBorder>
                    <Grid>
                        <Grid.Col span={8}>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Title order={3} style={{color: 'var(--mantine-color-dark-6)'}}>{product.title}</Title>
                            </Box>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Text fw={800}>Problem: </Text> <Text>{product.problem}</Text>
                            </Box>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Text fw={800}>Solution: </Text> <Text>{product.solution}</Text>
                            </Box>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Text fw={800}>Benefit: </Text> <Text>{product.benefit}</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={4} style={{
                            textAlign: 'center',
                            marginTop: '4em'
                        }}>
                            {product.icon}
                        </Grid.Col>
                    </Grid>
                </Paper>
            )
    }

}

export const ProductsComponent = () => {

    return (
        <Container>
            <Stack>
                <Center>
                    <Title><Text style={{
                        marginTop: 'var(--mantine-spacing-xl)',
                        color: 'var(--mantine-color-dark-3)'
                    }}>Flexi Email Products</Text></Title>
                </Center>
                <Stack style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                    {products.map(product => {
                        return <ProductTile product={product}/>
                    })}
                </Stack>
                <Center style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                    <CallToActionComponent/>
                </Center>
            </Stack>
        </Container>
    );
};
