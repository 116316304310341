import {
    createContext,
    ReactNode,
    useState,
} from 'react';


import {
    DataTableModal,
    FormModal,
} from '@flexinet/ui-components';

import {
    Box,
    Button
} from '@mantine/core';

import {
    AiOutlineEdit,
    AiOutlineMessage,
    AiOutlinePlusCircle
} from 'react-icons/ai';

type ModalContextType = {
    openModal: () => void,
    closeModal: () => void,
}

export const ModalContext = createContext<ModalContextType>({
                                                                openModal: () => {
                                                                    return;
                                                                },
                                                                closeModal: () => {
                                                                    return;
                                                                },
                                                            });


type ModalProviderProps = {
    buttonOptions: {
        label: string,
        variant: string,
        iconType: string,
        size?: string
    },
    title: string,
    confirmText?: string,
    onConfirm?: () => void,
    children?: ReactNode;
}

export const ModalContextProvider = ({
                                         buttonOptions = {
                                             variant: 'primary',
                                             label: 'Add Record',
                                             iconType: 'add',
                                             size: 'large',
                                         },
                                         title = 'All Records',
                                         confirmText = 'Save',
                                         onConfirm,
                                         children,
                                     }: ModalProviderProps) => {


    const [visible, setVisible] = useState(false);


    const openModal = () => {
        setVisible(false);
    };

    const closeModal = () => {
        setVisible(false);
    };

    return (
        <ModalContext.Provider
            value={{
                openModal,
                closeModal,
            }}
        >
            {buttonOptions.variant === 'primary' ? (
                <Button
                    variant={'filled'}
                    leftSection={
                        buttonOptions.iconType === 'add' ? (
                            <AiOutlinePlusCircle/>
                        ) : (
                            <AiOutlineEdit/>
                        )
                    }
                    size={
                        buttonOptions.size
                            ? buttonOptions.size
                            : 'large'
                    }
                    onClick={() => setVisible(true)}
                >
                    {buttonOptions.label}
                </Button>
            ) : (
                <Button
                    leftSection={
                        buttonOptions.iconType === 'add' ? (
                            <AiOutlinePlusCircle/>
                        ) : buttonOptions.iconType === 'edit' ? (<AiOutlineEdit/>) : (<AiOutlineMessage/>)


                    }
                    size={
                        buttonOptions.size
                            ? buttonOptions.size
                            : 'large'
                    }
                    onClick={() => setVisible(true)}
                >
                    {buttonOptions.label}
                </Button>
            )}

            {visible ? (
                <>
                    {typeof onConfirm !== 'undefined' ? (
                        <DataTableModal
                            title={title}
                            confirmText={confirmText}
                            onCancel={closeModal}
                            onConfirm={onConfirm}
                        >
                            {children}
                        </DataTableModal>
                    ) : (
                        <FormModal
                            title={title}
                            text={confirmText as string}
                            // onCancel={closeModal}
                        >
                            <Box>{children}</Box>
                        </FormModal>
                    )}
                </>
            ) : null}


        </ModalContext.Provider>
    );
};
