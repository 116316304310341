import {visibilitySelector,} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {
    addTableColumn,
    setTableColumns
} from "../reducers";


export const usePersonasTableColumns = () => {

    const dispatch = useAppDispatch();

    const {
        columns,
    } =
        useAppSelector((state) => visibilitySelector(state));


    // FETCH
    const handleAddTableColumn = (column: object) => {
        dispatch(addTableColumn(column));
    };

    const handleSetTableColumns = (columns: object[]) => {
        dispatch(setTableColumns(columns));
    };


    return {
        columns,
        handleAddTableColumn,
        handleSetTableColumns,
    };
};
