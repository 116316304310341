import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          from: Yup.array()
                                                   .required('Required'),
                                          to: Yup.array()
                                                 .required('Required'),
                                          subject: Yup.string()
                                      });
