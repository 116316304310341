import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';


import {Notifications} from '@mantine/notifications';
import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";

import {ModalsProvider} from "@mantine/modals";

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-datatable/styles.css';
import '@flexinet/ui-components/style.css'

import {MantineProvider} from "@mantine/core";
import {theme} from "./theme.ts";
import {AuthProvider} from "./context/AuthContext.tsx";
import {routesConfig} from "./config/routes.tsx";
import {VerifiedPermissionsProvider} from "./context/VerifiedPermissionsProvider.tsx";
import {actions} from "./config/rbac/actions.ts";


const router = createBrowserRouter(routesConfig)

export const App = () => {

    return (
        <MantineProvider theme={theme}>
            <AuthProvider>
                <VerifiedPermissionsProvider actions={actions}>
                    <UserAttributesContextProvider>
                        <ModalsProvider>
                            <Notifications position={'bottom-left'}/>
                            <RouterProvider router={router}/>
                        </ModalsProvider>
                    </UserAttributesContextProvider>
                </VerifiedPermissionsProvider>
            </AuthProvider>
        </MantineProvider>
    );
}

