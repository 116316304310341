import {
    useEffect,
    useState,
} from 'react';

import {
    Badge,
    Button,
    Card,
    Center,
    Container,
    Divider,
    Grid,
    Group,
    NavLink,
    Paper,
    SimpleGrid,
    Skeleton,
    Stack,
    Title,
} from '@mantine/core';


import {TbGauge} from 'react-icons/tb';

import {AiOutlineArrowRight} from 'react-icons/ai';

import {NavLink as ReactRouteNavLink} from 'react-router-dom';

import {IoMdOptions} from 'react-icons/io';
import {
    WidgetDataPoint,
    WidgetType,
} from '../../types.ts';

const WidgetSkeleton = () => {
    return (
        <Card>
            <Group justify='space-between'>
                <Skeleton width='88%' height={20}/>
                <Skeleton width='10%' height={20}/>
            </Group>

            <Divider/>
            <SimpleGrid cols={2} my='lg'>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={10} mt={6} width='100%' radius='xl'/>
                    <Skeleton height={10} mt={6} width='100%' radius='xl'/>
                    <Skeleton height={10} mt={6} width='100%' radius='xl'/>
                </Stack>
                <Skeleton height={100} mt={6} width='100%' radius='xl'/>
            </SimpleGrid>
            <Divider/>
            <Group>
                <Skeleton height={30} mt={6} width='100%' radius='xl'/>
                <Skeleton height={30} mt={6} width='100%' radius='xl'/>
            </Group>
        </Card>
    );
};

const DataPointEntry = ({item}: { item: WidgetDataPoint }) => (
    <Title order={4}>
        <Group>
            {item.value !== null ? (
                <Badge variant='dot' color={item.color}>
                    {parseInt(item.value as string)}
                </Badge>
            ) : null}
            {item.label}
        </Group>
    </Title>
);

const WidgetHeader = ({widget}: { widget: WidgetType }) => {
    const showOptionsButton = false;
    return (
        <Group justify='space-between' p='sm'>
            <Title order={2}>{widget.title}</Title>
            {showOptionsButton ? <Button variant='subtle'>
                <IoMdOptions size={14}/>
            </Button> : null}
        </Group>
    );
};

const WidgetDataPoints = ({widget}: { widget: WidgetType }) => {
    return (
        <Stack gap='lg' m='lg'>
            {widget.dataPoints && widget.dataPoints.map((item, index) => (
                <DataPointEntry item={item} key={index}/>
            ))}
        </Stack>
    );
};

const WidgetMetrics = ({widget}: { widget: WidgetType }) => {
    return (
        <Center>
            {widget.metrics.map((metric, index) => {
                if (metric.visible === true) {
                    return (
                        <Paper
                            shadow='lg'
                            radius='lg'
                            p='md'
                            m='xl'
                            withBorder
                            key={index}
                        >
                            <TbGauge size={24}/>
                            <Center>
                                <Title order={3}>
                                    {metric.value}
                                    {metric.unit}
                                </Title>
                            </Center>
                            <Center>
                                <Title order={4}>{metric.label}</Title>
                            </Center>
                            <Center my='lg'>
                                {metric.insight.icon}
                                <Title order={6}>{metric.insight.label}</Title>
                            </Center>
                        </Paper>
                    );
                } else {
                    return null;
                }
            })}
        </Center>
    );
};

const WidgetDataPointsAndMetrics = ({widget}: { widget: WidgetType }) => {
    return (
        <Grid>
            <Grid.Col span={{
                base: 12,
                lg: 6,
                xl: 7
            }}>
                <WidgetDataPoints widget={widget}/>
            </Grid.Col>
            <Grid.Col span={{
                base: 12,
                lg: 6,
                xl: 7
            }}>
                <WidgetMetrics widget={widget}/>
            </Grid.Col>
        </Grid>
    );
};

const WidgetActionsToolbar = ({widget}: { widget: WidgetType }) => {
    return (
        <Group>
            {widget.actionItems.map((item, index) => {

                                        if (item.visible === true) {

                                            return (
                                                <NavLink
                                                    label={item.label}
                                                    description={item.description}
                                                    // icon={item.icon}
                                                    rightSection={
                                                        <AiOutlineArrowRight size={12} stroke={'1.5'}/>
                                                    }
                                                    component={ReactRouteNavLink}
                                                    to={typeof item.to !== 'undefined' ? item.to : ''}
                                                    key={index}
                                                />
                                            );

                                        } else {
                                            return null;
                                        }
                                    },
            )}
        </Group>
    );
};

export const DashboardWidgetBuilder = ({widget}: { widget: WidgetType }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    if (loading) {
        return <WidgetSkeleton/>;
    }

    return (
        <Card shadow='sm' mb='sm'>
            <Card.Section withBorder>
                <WidgetHeader widget={widget}/>
            </Card.Section>

            {widget.content ? (
                widget.content
            ) : (
                <Container my='lg'>
                    <WidgetDataPointsAndMetrics widget={widget}/>
                </Container>
            )}

            <Card.Section withBorder>
                <WidgetActionsToolbar widget={widget}/>
            </Card.Section>
        </Card>
    );
};
