import {
    AiOutlineTeam,
    AiOutlineWallet
} from "react-icons/ai";
import {ReactNode} from "react";
import {
    MdOutlineInsights,
    MdPrivacyTip
} from "react-icons/md";


export type ProductDescriptionType = {
    title: string,
    problem: string,
    solution: string,
    benefit: string,
    icon: ReactNode,
    smallIcon: ReactNode,
    path: string,
    position: string,
}


export const products = [
    {
        title: "Streamline Vendor Communication",
        problem: "Managing communications from multiple vendors is frustrating and time-consuming, with emails scattered across inboxes.",
        solution: "Flexi Email lets you create dedicated aliases for each vendor (e.g., AcmeCorp@flexi.email). All their emails land in one place, organized and easily searchable.",
        benefit: "Save time filtering and searching emails, improve organization, and ensure you never miss critical updates from vendors.",
        icon: <AiOutlineWallet size={'9em'} color={'var(--mantine-color-flexinet-0)'}/>,
        smallIcon: <AiOutlineWallet size={'3em'} color={'var(--mantine-color-flexinet-0)'}/>,
        path: '/products',
        position: 'left'
    },
    {
        title: "Protect Your Privacy",
        problem: "Sharing your personal email address with businesses exposes you to spam and privacy risks.",
        solution: "Create disposable aliases for signups, online purchases, or temporary collaborations. Forward important emails to your secure inbox while keeping your personal address private.",
        benefit: "Reduce spam, control who has access to your real email address, and enjoy peace of mind knowing your privacy is protected.",
        icon: <MdPrivacyTip size={'9em'} color={'var(--mantine-color-black-8)'}/>,
        smallIcon: <MdPrivacyTip size={'3em'} color={'var(--mantine-color-black-8)'}/>,
        path: '/products',
        position: 'right'
    },
    {
        title: "Enhance Team Collaboration",
        problem: "Shared inboxes are messy, and internal email threads can be confusing.",
        solution: "Create team-specific aliases (e.g., sales@flexi.email, support@flexi.email). Everyone on the team can access and respond to emails, ensuring transparency and streamlined communication.",
        benefit: "Improve team collaboration, avoid email thread chaos, and ensure consistent responses to customer inquiries.",
        icon: <AiOutlineTeam size={'9em'} color={'var(--mantine-color-flexinet-0)'}/>,
        smallIcon: <AiOutlineTeam size={'3em'} color={'var(--mantine-color-flexinet-0)'}/>,
        path: '/products',
        position: 'left'
    },
    {
        title: "Gain Marketing Insights",
        problem: "Tracking the effectiveness of marketing campaigns across multiple email addresses is difficult.",
        solution: "Use Flexi Email to create campaign-specific aliases (e.g., summer2024campaign@flexi.email, newproduct@flexi.email). Analyze open rates, click-throughs, and unsubscribe rates on a centralized platform.",
        benefit: "Gain actionable insights into campaign performance, optimize your marketing efforts, and maximize ROI.",
        icon: <MdOutlineInsights size={'9em'} color={'var(--mantine-color-red-8)'}/>,
        smallIcon: <MdOutlineInsights size={'3em'} color={'var(--mantine-color-red-8)'}/>,
        path: '/products',
        position: 'right'
    },

] as ProductDescriptionType[];

