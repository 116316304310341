export enum rbacActions {

    GetDashboard = 'GetDashboard',

    ListAliases = 'ListAliases',
    GetAlias = 'GetAlias',
    CreateAlias = 'CreateAlias',
    UpdateAlias = 'UpdateAlias',
    DeleteAlias = 'DeleteAlias',

    ListContacts = 'ListContacts',
    GetContact = 'GetContact',
    CreateContact = 'CreateContact',
    UpdateContact = 'UpdateContact',
    DeleteContact = 'DeleteContact',

    ListMessages = 'ListMessages',
    GetMessage = 'GetMessage',
    CreateMessage = 'CreateMessage',
    UpdateMessage = 'UpdateMessage',
    DeleteMessage = 'DeleteMessage',

    ListTags = 'ListTags',
    GetTag = 'GetTag',
    CreateTag = 'CreateTag',
    UpdateTag = 'UpdateTag',
    DeleteTag = 'DeleteTag',

    ListPersonas = 'ListPersonas',
    GetPersona = 'GetPersona',
    CreatePersona = 'CreatePersona',
    UpdatePersona = 'UpdatePersona',
    DeletePersona = 'DeletePersona',


    ListServicePlans = 'ListServicePlans',
    UpdateServicePlan = 'UpdateServicePlan',
    DeleteServicePlan = 'DeleteServicePlan',
}

export const actions = [
    rbacActions.GetDashboard,

    rbacActions.ListAliases,
    rbacActions.GetAlias,
    rbacActions.CreateAlias,
    rbacActions.UpdateAlias,
    rbacActions.DeleteAlias,

    rbacActions.ListContacts,
    rbacActions.GetContact,
    rbacActions.CreateContact,
    rbacActions.UpdateContact,
    rbacActions.DeleteContact,

    rbacActions.ListMessages,
    rbacActions.GetMessage,
    rbacActions.CreateMessage,
    rbacActions.UpdateMessage,
    rbacActions.DeleteMessage,

    rbacActions.ListTags,
    rbacActions.GetTag,
    rbacActions.CreateTag,
    rbacActions.UpdateTag,
    rbacActions.DeleteTag,

    rbacActions.ListPersonas,
    rbacActions.GetPersona,
    rbacActions.CreatePersona,
    rbacActions.UpdatePersona,
    rbacActions.DeletePersona,

    rbacActions.ListServicePlans,
    rbacActions.UpdateServicePlan,
    rbacActions.DeleteServicePlan,
];

export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);