import {Message,} from '../types';
import {
    useEffect,
    useState
} from "react";
import {Paper} from '@mantine/core';
import {downloadData} from 'aws-amplify/storage';

export const MessageHtml = ({record}: { record: Message }) => {

    const [messageHtml, setMessageHtml] = useState('')


    useEffect(() => {
        const getMessage = async (record: Message) => {

            // const session = await fetchAuthSession();
            // const credentials = session.credentials;
            //
            // const client = new S3Client({
            //                                 region: 'us-west-2',
            //                                 credentials: credentials
            //                             });
            //
            // const params = {
            //     "Bucket": 'flexinet-ses-infra-dev-parsedmessagesbucketf941d74-8md3jpft2y38',
            //     "Key": record.htmlPath
            // };
            // console.debug(params)
            //
            // const command = new GetObjectCommand(params);
            //
            // const response = await client.send(command);
            // console.log('Get object command response', response)
            //
            //
            // const bodyString = await streamToString(response.Body)
            // console.log('BODY STRING', bodyString)


            // get the signed URL string
            const {
                body,
                eTag
            } = await downloadData({
                                       path: record.htmlObjectKey as string,
                                       options: {
                                           // download: true
                                       }
                                   }).result;

            console.log('BODY', body)
            console.log('ETag', eTag)

            body.text()
                .then((html: string) => {
                    setMessageHtml(html)
                })

        }

        getMessage(record)

    }, [record.htmlPath])


    return (
        <Paper p={'lg'}>
            <div dangerouslySetInnerHTML={{__html: messageHtml}}/>
        </Paper>
    );


};

