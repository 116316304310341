import {Alias,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator
} from "@flexinet/ui-components";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {Stack} from '@mantine/core';


export const Profile = ({record}: { record: Alias }) => {

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }, {
            label: 'Description',
            value: record.description as string,
            span: 12,
        }, {
            label: 'User Identifier ( sub )',
            value: record.sub as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }, {
            label: 'Status',
            value: record.status as string,
            span: 12,
        }];

    const properties2 = [
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: rbacActions.UpdateAlias,
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: rbacActions.UpdateAlias,
            span: 12,
        },
    ];


    return (
        <Stack gap={'xl'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};