import {Message,} from '../types';
import {
    DeleteRecordsConfirmationButton,
    FlexiButton,
} from "@flexinet/ui-components";
import {Group} from "@mantine/core";
import {AiOutlineRead} from "react-icons/ai";
import {TagRecordButton} from "../../tags/buttons/TagRecordButton.tsx";
import {showSuccessNotification} from "../../../components/ServiceNotifications.tsx";
import {useNavigate} from "react-router-dom";


export const MessageToolbar = (
    {
        record,
        handleUpdate,
        handleDelete
    }: {
        record: Message,
        handleUpdate: (record: Message) => void,
        handleDelete: () => void
    }
) => {


    const navigate = useNavigate()


    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        selected,
        ...rest
    } = record as Message & { selected: boolean };


    return (
        <Group my={"xl"} justify="center" gap={'sm'}>
            <FlexiButton leftSection={<AiOutlineRead/>} variant="subtle" onClick={() => {

                const newStatus = record.status === 'READ' ? 'UNREAD' : 'READ';
                handleUpdate({...rest, ...{status: newStatus}});

            }}>Read / Unread </FlexiButton>


            <TagRecordButton
                disabled={false}
                record={record}
                handleConfirm={() => {
                    showSuccessNotification();
                    navigate(`/messages/${record.id}`);
                }}/>

            <DeleteRecordsConfirmationButton
                disabled={false}
                variant={'subtle'}
                handleConfirm={() => {
                    handleDelete()
                    navigate(-1);
                }}/>

        </Group>
    );

};
