export const createPersona = `
    mutation createPersona($input: CreatePersonaInput!) {
        createPersona(input:$input) {
            id
            givenName
            familyName
            email
            note
            status
            created
            createdByUsername
            updated       
        }
}`;

export const updatePersona = `
    mutation updatePersona($input: UpdatePersonaInput!) {
        updatePersona(input:$input){
            id
            givenName
            familyName
            email
            note
            status
            created
            createdByUsername
            updated       
        }
}`;

export const deletePersona = `
    mutation deletePersona($input: DeletePersonaInput!) {
        deletePersona(input:$input) 
}`;
