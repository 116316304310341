import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Persona} from '../types';
import {
    Badge,
    Group
} from "@mantine/core";
import {FlexiCopyButton} from "@flexinet/ui-components";

export const personasInitialColumnSet = [
    {
        id: 'name',
        accessor: 'name',
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: Persona) => <NavLink
            to={`/personas/${item.id}`}>{[item.givenName, item.familyName].join(' ')}</NavLink>,
    },
    {
        id: 'email',
        accessor: 'email',
        title: I18n.get('Email'),
        width: '1fr',
        render: (item: Persona) => <Group>{item.email}<FlexiCopyButton
            value={item.email}/></Group>,

    },
    {
        id: 'status',
        accessor: 'status',
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: Persona) => item.status == 'ENABLED' ? <Badge color={'green'}>Enabled</Badge> :
            <Badge color={'red'}>Disabled</Badge>,
    },
    {
        id: 'created',
        accessor: 'created',
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Persona) => formatTimestamp(item.created as string),
    },
];
