import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';


import {
    AiOutlineAim,
    AiOutlineHome,
    AiOutlineInbox,
    AiOutlineMail,
    AiOutlineRead,
    AiOutlineTag,
    AiOutlineTransaction,
    AiOutlineUser
} from 'react-icons/ai'

import {IconContext} from "react-icons";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: rbacActions.GetDashboard,
        }, {
            id: uuidv4(),
            link: '/messages',
            label: 'Email Messages',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineMail/></IconContext.Provider>,
            rbac: rbacActions.ListMessages,
            defaultOpened: true,
            links: [{
                id: 'all',
                link: '/messages/?status=inbox',
                label: 'Inbox',
                icon: <IconContext.Provider value={{size: '1.6em'}}><AiOutlineInbox/></IconContext.Provider>,
                rbac: rbacActions.ListMessages,
            }, {
                id: 'read',
                link: '/messages/?status=read',
                label: 'Read',
                icon: <IconContext.Provider value={{size: '1.6em'}}><AiOutlineRead/></IconContext.Provider>,
                rbac: rbacActions.ListMessages,
            }],
        }, {
            id: uuidv4(),
            link: '/aliases',
            label: 'Aliases',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineAim/></IconContext.Provider>,
            rbac: rbacActions.ListAliases,
        }, {
            id: uuidv4(),
            link: '/personas',
            label: 'Personas',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineUser/></IconContext.Provider>,
            rbac: rbacActions.ListPersonas,
        }, {
            id: uuidv4(),
            link: '/tags',
            label: 'Tags',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineTag/></IconContext.Provider>,
            rbac: rbacActions.ListTags,
        }, {
            id: uuidv4(),
            link: '/contacts',
            label: 'Contacts',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineUser/></IconContext.Provider>,
            rbac: rbacActions.ListContacts,
        }, {
            id: uuidv4(),
            link: '/service-plans',
            label: 'Service Plans',
            icon: <AiOutlineTransaction size={'2em'}/>,
            rbac: rbacActions.ListServicePlans,
        }] as PlatformNavbarMenuItem[];

};

