import {
    deletePersona,
    fetchPersonas,
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector,
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {deleteAliasWithName} from "../../aliases/reducers/recordsSlice.ts";

export const usePersonas = () => {

    const dispatch = useAppDispatch();


    const {
        items,
        visibleItems,
        visibleCount,
    } =
        useAppSelector((state) => visibilitySelector(state));


    const selection = useAppSelector((state) => selectionSelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );


    // FETCH
    const fetchRecords = () => {
        dispatch(fetchPersonas());
    };

    const deleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {

                const record = selection[key];
                const name = [record.givenName, record.familyName].join('.');
                dispatch(deleteAliasWithName(name))
                    .then(
                        () => dispatch(deletePersona(key)),
                    );


            }
        }
    };


    return {
        items,
        visibleItems,
        visibleCount,
        loading,
        fetchRecords,
        deleteRecords,
    };
};
