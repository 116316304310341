import {
    ReactElement,
    useEffect,
} from 'react';

import {
    Paper,
    Stack
} from '@mantine/core';

import {useMessages} from '../hooks/useMessages';

import {SimpleTableBatchAction,} from '@flexinet/ui-components';
import {useMessagesSelection} from '../hooks/useMessagesSelection';
import {Message,} from '../types';
import {useMessagesSearch} from "../hooks/useMessagesSearch.ts";
import {useMessagesPaging} from "../hooks/useMessagesPaging.ts";
import {useMessagesTableColumns} from "../hooks/useMessagesTableColumns.tsx";
import {useAuth} from "../../../hooks/useAuth.ts";

import {DataTable} from 'mantine-datatable';
import classes from './RecordsTable.module.css';
import {
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";


export const RecordsTable = (
    {
        extraTools,
        settings,
    }: {
        extraTools?: ReactElement[],
        settings?: { label: string, onClick: () => void }[]
    }) => {

    const navigate = useNavigate()

    const {
        status
    } = useParams()

    const [searchParams] = useSearchParams();
    const tag = searchParams.get('tag')
    console.debug('searchParams', searchParams.get('tag'))

    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const {allowedActions} = useVerifiedPermissions()

    const {
        visibleItems,
        visibleCount,
        fetchRecords,
    } = useMessages();


    const {
        columns,
    } = useMessagesTableColumns();

    const {
        handleFilterByKeyword,
    } = useMessagesSearch();

    const {
        limit,
        page,
        handleItemsPerPageChange,
        handlePageChange,
    } = useMessagesPaging();

    const {
        selection,
        handleRowSelection,
        handleClearSelection,
    } = useMessagesSelection();


    // const {
    //     updateRecord,
    // } = useMessage(selectedRecord?.id);


    useEffect(() => {
        fetchRecords();
    }, [tag, status]);


    let selectedRecords = undefined;
    if (allowedActions.includes('DeleteMessage')) {
        selectedRecords = Object.keys(selection)
                                .map(key => selection[key])
    }


    return (
        <>
            <Paper p={'md'} shadow={'sm'}>
                <Stack>
                    <SimpleTableBatchAction
                        limit={limit}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        settings={settings}
                        handleFilterByKeyword={handleFilterByKeyword}
                        extraTools={extraTools}
                    />


                    <DataTable
                        minHeight={200}
                        noRecordsText="No records found"
                        verticalSpacing={'sm'}
                        withTableBorder
                        borderRadius={'md'}
                        shadow={'sm'}
                        striped
                        columns={columns}
                        records={visibleItems}
                        totalRecords={parseInt(visibleCount)}
                        recordsPerPage={parseInt(limit)}
                        page={parseInt(page)}
                        onPageChange={handlePageChange}
                        paginationText={({
                                             from,
                                             to,
                                             totalRecords
                                         }) => `Records ${from} - ${to} of ${totalRecords}`}
                        paginationSize={'md'}
                        selectedRecords={selectedRecords}
                        onSelectedRecordsChange={(items) => {
                            handleClearSelection();
                            items.forEach(item => {
                                handleRowSelection(item)
                            })
                        }}
                        onRowClick={({
                                         record,
                                     }) => {

                            navigate('/messages/' + record.id);

                            // if (record.status === 'UNREAD') {
                            //     updateRecord({...record, ...{status: 'READ'}});
                            // }

                        }}
                        rowClassName={(record: Message) => {
                            if (record.status === 'UNREAD') {
                                return classes.unread;
                            } else if (record.status === 'READ') {
                                return classes.read;
                            }
                        }}
                    />

                </Stack>

            </Paper>

        </>
    );
};
