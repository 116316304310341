import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';
import {useAliasesSelection} from "../hooks/useAliasesSelection.ts";
import {useAliases} from "../hooks/useAliases.ts";


export const DeleteRecordButton = () => {

    const {deleteRecords} = useAliases();

    const {
        selection,
        handleClearSelection,
    } = useAliasesSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }

    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};

